enum LandingRoutes {
  contact = 'contact',
  client = 'client',
  business = 'business',
  home = 'home',
  terms = 'terms',
  privacy = 'privacy',
  company = 'company',
  blog = 'blog',
  salon = 'salons',
  about = 'about',
  booking = 'booking',
  bk = 'bk',
  pricing = 'pricing',
  jobs = 'jobs',
  apply = 'apply',
  policy = 'policy',
  cookie = 'cookie',
  review = 'review',
  specialist = 'specialist'
}

enum DashboardRoutes {
}

enum AuthRoutes {
  auth = 'auth',
  authorize = 'authorize',
  login = 'login',
  register = 'register',
  forgot = 'forgot',
  reset = 'reset'
}

enum CommonRoutes {
  detail = 'detail',
  general = 'general',
  notFound = 'not-found',
  oldPageBrowser = 'old-page-browser'
}

export type AppRoutes = typeof AppRoutes;
export const AppRoutes = {
  ...LandingRoutes,
  ...DashboardRoutes,
  ...AuthRoutes,
  ...CommonRoutes,
};

